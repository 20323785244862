/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle */
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { lockedTestableProductsTrackEvents } from '@features/locked-testable-products/data';
import { MerchantInfo, OrderTestingInfo } from '@features/locked-testable-products/domain';
import { user } from '@features/user/data';
import { readMoreChars } from '@mongez/reinforcements';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { LogGTagEventUseCase } from 'src/app/core/usecases/analytics/log-gtag-event.usecase';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetUserCountryUseCase } from 'src/app/core/usecases/user-location/get-user-country.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { SKU_PERFORMANCE_USER_FEATURE } from 'src/app/presentation/shared/constants';
import { AVAILABILITY_STATUSES } from 'src/app/presentation/shared/constants/product-availability';
import { FEATURED_CATEGORY_NAME } from 'src/app/presentation/shared/constants/products-landing-page';
import { Variant, VariantGroup } from 'src/app/presentation/shared/interfaces/variant';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';
import { HoverStyleDirective } from '../../../shared/directives/hover-style.directive';
import { CountdownTimerPipe } from '../../../shared/pipes/countdown-timer.pipe';
import { CurrencyShortNamePipe } from '../../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../../shared/pipes/currency-translate.pipe';
import { LockedTestableProductsInfoComponent } from './locked-testable-products-info/locked-testable-products-info.component';
import { ProductCardPresenter } from './presentation/product-card.presenter';
import { ProductCardSideEffects } from './presentation/product-card.side-effects';
import { ProductCardViewEvents } from './presentation/product-card.view-events';
import { ProductCardViewState } from './presentation/product-card.view-state';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    HoverStyleDirective,
    RouterLink,
    TranslateModule,
    CountdownTimerPipe,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
    NgFor,
    LockedTestableProductsInfoComponent,
  ],
  providers: [ProductCardPresenter],
})
export class ProductCardComponent
  extends BaseComponent<
    ProductCardPresenter,
    ProductCardViewState,
    ProductCardViewEvents,
    ProductCardSideEffects
  >
  implements OnDestroy
{
  @Input() variantGroup: VariantGroup;

  @Input() product: Variant & {
    orderTestingInfo?: OrderTestingInfo;
    merchantInfo?: MerchantInfo;
  };

  @Input() tooltipVisible = true;

  @Input() isFeaturedProduct = false;

  @Input() isFromLockedCarousel = false;

  @Input() isUpsellableProduct = false;

  @Input() recommendationId: string;

  @Input() shouldShowStockDistribution: boolean;

  @Output() click: EventEmitter<void> = new EventEmitter<void>();

  public productLink: string;

  public hasVariants = false;

  public availabilityStatuses: any;

  public productAnalyticsEnabled: boolean;

  public assetsPath = 'assets/img/';

  public storesAssetsPath = 'assets/img/stores/';

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  public presenter: ProductCardPresenter = inject(ProductCardPresenter);

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private _responsiveService: ResponsiveService,
    private _translateService: TranslateService,
    private _getUserCountryUseCase: GetUserCountryUseCase,
    private _logGtagEventUseCase: LogGTagEventUseCase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
  ) {
    super();
  }

  public onInit(): void {
    if (this.variantGroup) {
      this.product = this.variantGroup.primaryVariant!;
      this.checkHasVariants();
    }
    this.productLink = this.router.serializeUrl(
      this.router.createUrlTree(['/product-details', this.product._id], {
        queryParams: { productName: this.product.productName },
      }),
    );
    this.availabilityStatuses = AVAILABILITY_STATUSES;
    this.checkForProductAnalytics();
    this.presenter.emitViewEvent({
      type: 'Init',
      productId: this.product._id!,
      isPreOrderable: this.product.isPreOrderable!,
      productImg: this.product.productPicture,
      productIsOnSale: !!(this.product.sale?.productProfit || this.product.sale?.productPrice),
      saleExpiresAt: this.product.sale?.saleExpiresAt,
      sku: this.product.prodID,
    });
  }

  checkForProductAnalytics(): void {
    const selectedCountry = this.viewState.currentCountry?.isoCode3.toLocaleLowerCase();
    this.productAnalyticsEnabled = this._checkUserFeatureExistsUseCase.execute(
      `${SKU_PERFORMANCE_USER_FEATURE}${selectedCountry}`,
    );
  }

  checkHasVariants(): void {
    this.hasVariants = this.variantGroup.variants && this.variantGroup.variants.length > 1;
  }

  onSideEffect(sideEffect: ProductCardSideEffects): void {
    switch (sideEffect.type) {
      case 'ShowSuccessToaster': {
        this.toastr.success(this._translateService.instant(sideEffect.messageKey));
        break;
      }
      case 'ShowErrorToaster': {
        this.toastr.error(this._translateService.instant(sideEffect.messageKey));
        break;
      }
      case 'TrackEvent': {
        switch (sideEffect.eventType) {
          case 'add_to_cart': {
            this._logAddToCartEventToAnalytics();
            this.trackMixpanelProductEvent('product_card_add_to_cart_in_section');

            if (
              this.viewState?.marketplaceV3_3Enabled &&
              this.product.merchantInfo?.orderTestingAvailable
            ) {
              this.trackMixpanelProductEvent(lockedTestableProductsTrackEvents.ADD_TO_CART);
            }
            break;
          }
          case 'add_to_catalog': {
            this._trackAddToCatalog();
            this.trackMixpanelProductEvent('product_card_add_to_catalog_in_section');
            break;
          }
          case 'remove_from_catalog': {
            this.trackMixpanelProductEvent('product_card_remove_from_catalog_in_section');
            break;
          }
          case 'add_to_store_clicked': {
            this.trackMixpanelStoreEvent(sideEffect.eventType);
          }
        }
      }
    }
  }

  private async _logAddToCartEventToAnalytics(): Promise<void> {
    const eventName = 'Home_page_add_to_cart';
    const payload = {
      'Taager ID': user.id,
      Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
      'User Location': await lastValueFrom(this._getUserCountryUseCase.execute()),
      Product: this.product.productName,
      categoryId: this.product.categoryId,
      'Taager Selling Price': this.product.productPrice,
    };
    this._logGtagEventUseCase.execute({ eventName, payload });
  }

  trackGoToProductMixpanelEvent(): void {
    this.trackMixpanelProductEvent('product_card_click_in_section');
    if (this.isUpsellableProduct) {
      this.trackMixpanelProductEvent('upsellable_product_click_in_section');
    }

    if (
      this.viewState?.marketplaceV3_3Enabled &&
      this.product.merchantInfo?.orderTestingAvailable
    ) {
      this.trackMixpanelProductEvent(lockedTestableProductsTrackEvents.CLICK_PRODUCT_CARD);
    }

    this.click.emit();
  }

  trackGoToProductStockAvailability(): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'view_stock_btn_product_card_clicked',
      payload: {
        productId: this.product.prodID,
      },
    });
  }

  private async _trackAddToCatalog(): Promise<void> {
    const eventName = 'Home_page_add_to_catalog';
    const payload = {
      'Taager ID': user.id,
      Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
      'User Location': await lastValueFrom(this._getUserCountryUseCase.execute()),
      Product: this.product.productName,
      categoryId: this.product.categoryId,
      'Taager Selling Price': this.product.productPrice,
    };
    this._logGtagEventUseCase.execute({ eventName, payload });
  }

  private trackMixpanelProductEvent(event_name: string): void {
    this._logMixpanelEventUseCase.execute({
      eventName: event_name,
      payload: {
        'Interface Version': 'v2',
        'Category Id': this.product.categoryId,
        'Category Name': this.isFeaturedProduct ? FEATURED_CATEGORY_NAME : this.product.Category,
        'Product Id': this.product.prodID,
        'Product Name': this.product.productName,
        'Product Price': this.product.productPrice,
        'Product Profit': this.product.productProfit,
        'Product Availability Status': this.product.productAvailability,
        'Is Has Variants': this.product.attributes && this.product.attributes.length > 0,
        'Is Added To Catalog': this.viewState.productIsInCatalog,
        recommendationId: this.recommendationId ? this.recommendationId : null,
        ...(this.isFromLockedCarousel && { 'Section Title': 'Locked Products Carousel' }),
      },
    });
  }

  private trackMixpanelStoreEvent(event_name: string): void {
    this._logMixpanelEventUseCase.execute({
      eventName: event_name,
      payload: {
        ...this.product,
        productSourcePage: 'home page',
        recommendationId: this.recommendationId ? this.recommendationId : null,
        store: this.viewState.storeProvider,
      },
    });
  }

  reduceChars(title: string): string {
    return readMoreChars(title, 50);
  }
}
