<div [dir]="direction" class="card-container d-flex flex-column">
  <div class="flash-sale-container" *ngIf="viewState.productIsOnSale">
    <div class="sale-title" *ngIf="viewState.flashSaleTitle">
      <span class="body2--bold">{{ viewState.flashSaleTitle }}</span>
    </div>
    <div
      class="sale-timer-container"
      [ngClass]="{ 'sale-timer-container__no-title': !viewState.flashSaleTitle }"
    >
      <span class="body2--bold" *ngIf="!product.sale.saleExpiresAt; else countDownTimerComponent"
        >عرض لفترة محدودة</span
      >
      <ng-template #countDownTimerComponent>
        <span class="body2--bold">{{
          this.viewState.saleCountDownTimer! | countdownTimerPipe : 'day:hr:min:secs'
        }}</span>
      </ng-template>
      <div class="dot"></div>
    </div>
  </div>

  <a
    class="card-img img-overflow"
    appHoverStyle
    (click)="trackGoToProductMixpanelEvent()"
    [routerLink]="['/product-details', product._id]"
    [queryParams]="{ productName: product.productName }"
    [attr.data-test-id]="
      product.productAvailability === availabilityStatuses.not_available ||
      product.productAvailability === availabilityStatuses.available_for_preorder
        ? ''
        : 'product-card-img'
    "
  >
    <img loading="lazy" width="100%" height="100%" [src]="this.viewState.productImg" />
  </a>
  <div
    *ngIf="
      shouldShowStockDistribution ||
      (viewState.marketplaceV3_3Enabled && product.merchantInfo?.stockInfo)
    "
    class="lock-product-icon"
  >
    <img src="assets/img/locked-products-lock.svg" />
  </div>
  <div
    class="add-to-store"
    *ngIf="viewState.userHasStore"
    [ngClass]="product.productAddedToStore ? 'add-to-store__added' : ''"
  >
    <button
      class="add-to-store__btn"
      (click)="presenter.emitViewEvent({ type: 'AddToStore' })"
      [disabled]="
        !product.isAvailableToSeller ||
        product.productAvailability === availabilityStatuses.not_available ||
        this.viewState.productIsNotOrderable
      "
    >
      <img
        class="add-to-store__btn__icon"
        src="{{
          product.productAddedToStore
            ? assetsPath + 'added-store.svg '
            : assetsPath + 'header-icons/shop-basket.svg'
        }}"
      />
      <img *ngIf="!product.productAddedToStore" src="{{ assetsPath + 'add-icon.svg' }}" />

      <p class="add-to-store__btn__text caption2--bold" *ngIf="product.productAddedToStore">
        {{ 'STORES.ADDED_TO_STORE' | translate }}
      </p>
    </button>

    <span class="store-tooltip">
      <img src="{{ storesAssetsPath + viewState.storeProvider + '.svg' }}" />

      <p class="caption2--bold">{{ 'STORES.ADD_TO_STORE' | translate }}</p>
    </span>
  </div>
  <div class="card-product-info-container">
    <div class="locked-product" *ngIf="variantGroup?.isLockedForMe">
      <img class="lock-icon" src="../../../assets/img/locked-products-lock.svg" />
      <p class="locked-product__text caption1--medium">منتج مقفول عليك لبيعه</p>
    </div>

    <app-locked-testable-products-info
      *ngIf="viewState.marketplaceV3_3Enabled && product.orderTestingInfo"
      [product]="product"
    ></app-locked-testable-products-info>

    <a
      class="product-title body2--bold"
      appHoverStyle
      (click)="trackGoToProductMixpanelEvent()"
      [routerLink]="['/product-details', product._id]"
      [queryParams]="{ productName: product.productName }"
    >
      {{ reduceChars(product.productName) }}
    </a>

    <div class="d-flex flex-row justify-content-between">
      <div class="sale-price">
        <span class="sale-price-title caption1--medium min-card-label">{{
          'PRODUCTS_PAGE.PRODUCT_CARD.MIN_SELLING_PRICE' | translate
        }}</span>
        <div class="on-sale">
          <div
            class="sale-product-price"
            [ngClass]="{
              'original-product-value': !viewState.productIsOnSale || !product.sale?.productPrice
            }"
          >
            <span class="title body1--bold">
              {{
                viewState.productIsOnSale && product.sale?.productPrice
                  ? product.sale?.productPrice
                  : product.productPrice
              }}
              {{
                viewState.currency?.iso4217Code | currencyTranslate | translate | currencyShortName
              }}
            </span>
          </div>
          <div
            class="orignal-price"
            *ngIf="viewState.productIsOnSale && product.sale?.productPrice"
          >
            <s class="body2--medium">{{ product.productPrice }}</s>
          </div>
        </div>
      </div>
      <div class="sale-profit">
        <span class="sale-title profit-title caption1--medium min-card-label">{{
          'PRODUCTS_PAGE.PRODUCT_CARD.MIN_PROFIT_FOR_YOU' | translate
        }}</span>
        <div class="on-sale updated-profit">
          <div class="sale-product-profit body1--bold">
            {{
              viewState.productIsOnSale && product.sale?.productProfit
                ? product.sale?.productProfit
                : product.productProfit
            }}
            {{
              viewState.currency?.iso4217Code | currencyTranslate | translate | currencyShortName
            }}
            <span *ngIf="tooltipVisible" class="tooltip-text caption1--medium">{{
              'PRODUCTS_PAGE.PRODUCT_CARD.PROFIT_DETAIL_HOVER' | translate
            }}</span>
          </div>
          <div
            class="orignal-profit"
            *ngIf="viewState.productIsOnSale && product.sale?.productProfit"
          >
            <s class="body2--medium">{{ product.productProfit }}</s>
          </div>
        </div>
      </div>
    </div>

    <p class="has-variants-text caption2--medium" *ngIf="hasVariants">
      {{ 'PRODUCTS_PAGE.PRODUCT_CARD.AVAILABLE_IN_VARIANTS' | translate }}
    </p>
    <section class="cta-container">
      <button
        [attr.data-test-id]="
          product.productAvailability === availabilityStatuses.not_available ||
          product.productAvailability === availabilityStatuses.available_for_preorder ||
          this.viewState.productIsNotOrderable ||
          !product.isAvailableToSeller
            ? ''
            : 'add-to-cart-btn'
        "
        class="add-to-cart-btn d-flex justify-content-between align-items-center"
        appHoverStyle
        (click)="this.presenter.emitViewEvent({ type: 'AddToCart' })"
        [disabled]="
          !product.isAvailableToSeller ||
          product.productAvailability === availabilityStatuses.not_available ||
          this.viewState.productIsNotOrderable
        "
      >
        <span class="body2--bold">{{ 'PRODUCTS_PAGE.ADD_TO_CART' | translate }}</span>
        <i class="add-to-cart-btn__icon icon icon-cart"></i>
      </button>
      <button class="favorite-btn" appHoverStyle>
        <i
          (click)="
            this.viewState.productIsInCatalog
              ? this.presenter.emitViewEvent({ type: 'RemoveFromCatalog' })
              : this.presenter.emitViewEvent({ type: 'AddToCatalog' })
          "
          class="icon favorite-btn__icon"
          [ngClass]="
            this.viewState.productIsInCatalog
              ? 'icon-heart-active favorite-btn__cataloged'
              : 'icon-heart favorite-btn__not-cataloged'
          "
        ></i>
      </button>
    </section>
    <section
      *ngIf="
        viewState.marketplaceV3_3Enabled
          ? product.discountedAsSecondInPlacement?.enabled && !product.orderTestingInfo
          : product.discountedAsSecondInPlacement?.enabled
      "
    >
      <div class="discounted-product-alert mt-0 mb-2">
        <img
          class="discounted-product-alert__img"
          src="assets/iconfont-input/discount-lightning.svg"
        />
        <p class="discounted-product-alert__text caption2--bold">
          {{ 'PRODUCTS_PAGE.PRODUCT_CARD.SECOND_IN_PLACEMENT_LABEL' | translate }}
        </p>
      </div>
    </section>
  </div>
</div>
