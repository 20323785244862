import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { mediaUrl } from '@features/shared/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MoengageService } from 'app/presentation/shared/services/mo-engage.service';
import { Subscription } from 'rxjs';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { CommercialCategoryTreeNode } from 'src/app/presentation/shared/interfaces/commercial-categories.interface';

@Component({
  selector: 'app-level-one-categories-sidebar',
  templateUrl: './level-one-categories-sidebar.component.html',
  styleUrls: ['./level-one-categories-sidebar.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, RouterLink, TranslateModule],
})
export class LevelOneCategoriesSidebarComponent implements OnInit, OnDestroy {
  @Input() categoriesNodes: CommercialCategoryTreeNode[];

  @Input() selectedCategory: { englishName: string; arabicName: string };

  @Input() isPreOrderable: Boolean;

  @Input() queryParamsObject: any;

  @Input() isNewPreOrderEnabled: Boolean;

  @Output() emittedCategoryId = new EventEmitter<{ id: string }>();

  public isEnglishLanguageSelected: boolean;

  private _languageChangeSubscription: Subscription;

  constructor(
    private _translateService: TranslateService,
    private _router: Router,
    private _moEngageService: MoengageService,
  ) {}

  ngOnInit(): void {
    this.isEnglishLanguageSelected = this._translateService.currentLang === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: ({ lang }: { lang: string }) => {
        this.isEnglishLanguageSelected = lang === ENGLISH_LANGUAGE;
      },
    });
  }

  public categoryIconUrl(icon: string): string {
    return mediaUrl(`commercial-categories/icons/${icon}`);
  }

  onCategoryClicked(id: string): void {
    this._moEngageService.trackEvent('User_Clicked_Category', {
      category: this.selectedCategory.englishName,
      Click_Type: 'Category',
    });

    if (this.isNewPreOrderEnabled) {
      this.emittedCategoryId.emit({ id });
    } else {
      this._router.navigate(['/', 'products', 'category', id], {
        queryParams: {
          currentPage: 1,
          items: this.queryParamsObject.items,
          sorting: this.queryParamsObject.sorting,
          preorderablProducts: this.isPreOrderable,
        },
      });
    }
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
